@charset "utf-8";

$accent-color: tomato;
@import "so-simple";

$gallery-background: #e9e7e5;
$gallery-caption: #e3e2e0;

.layout--home .page-header h1 {
  display: none;
}

.single-image, .gallery {
  background: $gallery-background;
  margin: 1rem 0 1.5rem;

  figure {
    margin: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    figcaption {
      align-self: stretch;
      background: $gallery-caption;
      font-style: italic;
      margin: 0;
      p {
        margin: 0.5rem;
      }

      .credit {
        font-style: normal;
        opacity: 0.8;
        margin-left: 0.2rem;
      }
    }

    img {
      max-height: 90vh;
    }
  }

  .splide__arrow[disabled] {
    display: none;
  }
}

table.summary {
  td.amount, td.quantity {
    text-align: right;
  }

  td.quantity {
    color: #777;
    font-weight: bold;
  }

  tr.subtotal-heading, tr.subtotal {
    color: #777;
    font-weight: bold;
    font-style: italic;
  }

  tr.total {
    font-weight: bold;
    font-style: italic;
  }

  tr:last-child, tr.subtotal {
    td {
      border-bottom: none;
    }
  }

  caption {
    text-align: left;
    font-style: italic;
    margin: 0.4rem 0;

    h3 {
      margin-bottom: 0;
    }
  }
}

.embed-container {
  display: flex;
  justify-content: center;
}

// Tweaks for the default theme styling

.layout--post .page-wrapper {
  @include breakpoint($large) {
    grid-template-columns: none;
    -webkit-box-pack: inherit;
    -ms-flex-pack: inherit;
    justify-content: space-between;
  }
}

.page-title {
  margin-bottom: 0.2em;
}

.layout--post .page-sidebar {
  margin-bottom: 1.5em;
}

.page-sidebar {
  text-align: left;

  .page-date,
  .read-time,
  .page-taxonomies-title,
  .page-taxonomies {
    font-family: $meta-font-family;
    font-style: italic;
    white-space: normal;
  }
}

.page-taxonomies-title {
  display: inline;
  font-size: inherit;
  font-weight: normal;
  text-transform: none;

  @include breakpoint($large) {
    display: inline;
    margin: inherit;
    padding: inherit;
    font-size: inherit;
    font-weight: normal;
  }
}

.page-taxonomies {
  display: inline;
  margin-right: 1em;
  padding: 0;
  list-style: none;

  @include breakpoint($large) {
    display: inline;
    margin: inherit;
    margin-right: 1em;
  }
}

// Footer styling

.site-footer {
  .mailchimp-form {
    margin-bottom: 2rem;

    h2 {
      margin-bottom: 1rem;
    }

    .mc-field-group, .submit-form {
      display: inline-block;
    }
  }
}
